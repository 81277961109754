var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-user-directory"},[_c('section',{staticClass:"py-5 bg10 bs-shadow mobile-top",attrs:{"data-v-5a90ec03":""}},[_c('div',{staticClass:"container",attrs:{"data-v-5a90ec03":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"top-search bs-large",attrs:{"type":"text","placeholder":"Search users by first name, last name, or company name"},domProps:{"value":(_vm.search)},on:{"click":function($event){return _vm.searchReset()},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),(_vm.loading == true)?_c('div',{staticClass:"col-xs-12 col-sm-12 col-12 col-lg-8"},[_c('div',{staticClass:"flex-box-wrap"},[_c('div',{staticClass:"flex-internal"},[_c('atom-spinner',{attrs:{"animation-duration":1000,"size":40,"color":'#575757'}}),_c('p',[_vm._v("Loading......")])],1)])]):(_vm.loading == false && !_vm.filteredUsers.length)?_c('div',{staticClass:"col-xs-12 col-sm-12 col-12 col-lg-8 flexVHcenter"},[_vm._m(1)]):_c('div',{staticClass:"col-xs-12 col-sm-12 col-12 col-lg-8"},[_c('div',{staticClass:"user-header d-flex align-items-center p-3 mb5 mt1r rounded bs-shadow"},[_c('div',{staticClass:"d-flex number-showing"},[_c('p',{staticClass:"mb0"},[_vm._v(" Page "+_vm._s(_vm.currPage)+" of "+_vm._s(Math.ceil(this.filteredUsers.length / 25))+" ")])]),_c('div',{staticClass:"pag-wrapper mlauto"},[_c('div',{staticClass:"nav-btns"},[(this.currPage === 1)?_c('button',{attrs:{"disabled":""}},[_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_arrow_left")]),_vm._v(" Back ")]):_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.setPage(_vm.currPage - 1)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_arrow_left")]),_vm._v(" Back ")]),(
										this.currPage === this.totalPage ||
											this.currPage == Math.ceil(this.filteredUsers.length / 25)
									)?_c('button',{attrs:{"disabled":""}},[_vm._v(" Next "),_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_arrow_right")])]):_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.setPage(_vm.currPage + 1)}}},[_vm._v(" Next "),_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_arrow_right")])])])])]),_c('div',_vm._l((_vm.filteredUsers.slice(
								_vm.pageStart,
								_vm.pageStart + _vm.countOfPage
							)),function(user,index){return _c('div',{key:user.Tid},[_c('div',{staticClass:"user bs-shadow br4"},[_c('div',{staticClass:"top-wrap"},[_c('div',{staticClass:"dflex mb0"},[_c('avatar',{staticClass:"mr10",attrs:{"color":"#fff","username":user.FirstName + ' ' + user.LastName,"backgroundColor":_vm.colorGetter[
													Math.floor(Math.random() * _vm.colorGetter.length)
												]}}),_c('div',{staticClass:"dflex vcenter"},[_c('div',[_c('h6',{staticClass:"mb5"},[_c('b',[_vm._v(_vm._s(user.FirstName + " " + user.LastName))])]),(user.Email)?_c('p',{staticClass:"dflex mb5 l1 text-muted"},[_c('small',[_c('i',{staticClass:"fas fa-envelope mr5"}),_c('b',{staticClass:"user-link"},[_c('a',{attrs:{"href":("mailto:" + (user.Email))}},[_c('span',[_vm._v(_vm._s(user.Email))])])])])]):_vm._e(),(user.PhoneNumber)?_c('p',{staticClass:"dflex mb0 l1 text-muted"},[_c('small',[_c('i',{staticClass:"fas fa-phone mr5"}),_vm._v(" "+_vm._s(user.PhoneNumber)+" ")])]):_vm._e()])])],1),_c('div',{staticClass:"company-link mlauto"},[_c('p',{staticClass:"lh1 mb0 company-button",on:{"click":function($event){return _vm.setCompany(user.Name)}}},[_c('small',[_vm._v(_vm._s(user.Name))])])])])])])}),0),_c('div',{staticClass:"user-header d-flex align-items-center p-3 mb5 mt5 mb1r rounded box-shadow"},[_c('div',{staticClass:"d-flex number-showing"},[_c('p',{staticClass:"mb0"},[_vm._v(" Page "+_vm._s(_vm.currPage)+" of "+_vm._s(Math.ceil(this.filteredUsers.length / 25))+" ")])]),_c('div',{staticClass:"pag-wrapper mlauto"},[_c('div',{staticClass:"nav-btns"},[(this.currPage === 1)?_c('button',{attrs:{"disabled":""}},[_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_arrow_left")]),_vm._v(" Back ")]):_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.setPage(_vm.currPage - 1)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_arrow_left")]),_vm._v(" Back ")]),(
										this.currPage === this.totalPage ||
											this.currPage == Math.ceil(this.filteredUsers.length / 25)
									)?_c('button',{attrs:{"disabled":""}},[_vm._v(" Next "),_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_arrow_right")])]):_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.setPage(_vm.currPage + 1)}}},[_vm._v(" Next "),_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_arrow_right")])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-4 d-none d-lg-block"},[_c('div',{staticClass:"card-block my-3 bs-shadow"},[_c('h5',{staticClass:"dflex"},[_c('i',{staticClass:"material-icons"},[_vm._v("help")]),_vm._v(" Using the user directory ")]),_c('hr'),_c('div',[_c('p',{staticClass:"info-box"},[_vm._v(" You can search above by a user's first name, last name, or by company name of their employer. ")]),_c('p',{staticClass:"info-box mb0"},[_vm._v(" You can also filter your results by clicking on the company name in the user results area. ")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/sad.svg")}}),_c('p',[_vm._v("Sorry, nothing was found...")])])}]

export { render, staticRenderFns }