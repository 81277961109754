<template>
  <div class="page-user-directory">
    <section data-v-5a90ec03 class="py-5 bg10 bs-shadow mobile-top">
      <div data-v-5a90ec03 class="container">
        <input
          class="top-search bs-large"
          type="text"
          placeholder="Search users by first name, last name, or company name"
          v-model="search"
          @click="searchReset()"
        />
      </div>
    </section>

    <div class="container">
      <div class="row">
        <div class="col-lg-4 d-none d-lg-block">
          <div class="card-block my-3 bs-shadow">
            <h5 class="dflex">
              <i class="material-icons">help</i> Using the user directory
            </h5>
            <hr />
            <div>
              <p class="info-box">
                You can search above by a user's first name, last name, or by
                company name of their employer.
              </p>
              <p class="info-box mb0">
                You can also filter your results by clicking on the company name
                in the user results area.
              </p>
            </div>
          </div>
        </div>

        <div v-if="loading == true" class="col-xs-12 col-sm-12 col-12 col-lg-8">
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner :animation-duration="1000" :size="40" :color="'#575757'" />
              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !filteredUsers.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-8 flexVHcenter"
        >
          <div>
            <img src="@/assets/img/sad.svg" />
            <p>Sorry, nothing was found...</p>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-8">
          <div class="user-header d-flex align-items-center p-3 mb5 mt1r rounded bs-shadow">
            <div class="d-flex number-showing">
              <p class="mb0">
                Page {{ currPage }} of
                {{ Math.ceil(this.filteredUsers.length / 25) }}
              </p>
            </div>

            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
										this.currPage === this.totalPage ||
											this.currPage == Math.ceil(this.filteredUsers.length / 25)
									"
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>

          <div>
            <div
              v-bind:key="user.Tid"
              v-for="(user, index) in filteredUsers.slice(
								pageStart,
								pageStart + countOfPage
							)"
            >
              <!--
          <div v-for="user in users" :key="user.Tid">
              -->
              <div class="user bs-shadow br4">
                <div class="top-wrap">
                  <div class="dflex mb0">
                    <avatar
                      class="mr10"
                      color="#fff"
                      v-bind:username="user.FirstName + ' ' + user.LastName"
                      v-bind:backgroundColor="
												colorGetter[
													Math.floor(Math.random() * colorGetter.length)
												]
											"
                    ></avatar>
                    <!--
                  {{colorGetter[Math.floor(Math.random() * colorGetter.length)]}}
                    -->
                    <div class="dflex vcenter">
                      <div>
                        <h6 class="mb5">
                          <b>{{ user.FirstName + " " + user.LastName }}</b>
                        </h6>
                        <p v-if="user.Email" class="dflex mb5 l1 text-muted">
                          <small>
                            <i class="fas fa-envelope mr5"></i>
                            <b class="user-link">
                              <a v-bind:href="`mailto:${user.Email}`">
                                <span>{{ user.Email }}</span>
                              </a>
                            </b>
                          </small>
                        </p>
                        <p v-if="user.PhoneNumber" class="dflex mb0 l1 text-muted">
                          <small>
                            <i class="fas fa-phone mr5"></i>
                            {{ user.PhoneNumber }}
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="company-link mlauto">
                    <p v-on:click="setCompany(user.Name)" class="lh1 mb0 company-button">
                      <small>{{ user.Name }}</small>
                    </p>
                  </div>
                </div>
                <!--
              <div class="user-info">
                <p v-if="user.Email" class="dflex mb10 l1"><i class="fas fa-envelope mr5"></i><b><span :href="`mailto:${user.Email}`">{{user.Email}}</span></b></p>
                <p v-if="user.PhoneNumber" class="dflex mb0 l1"><i class="fas fa-phone mr5"></i> {{user.PhoneNumber}}</p>
              </div>
                -->
              </div>
              <!-- card -->
            </div>
          </div>

          <!-- bottom pagination -->
          <div class="user-header d-flex align-items-center p-3 mb5 mt5 mb1r rounded box-shadow">
            <div class="d-flex number-showing">
              <p class="mb0">
                Page {{ currPage }} of
                {{ Math.ceil(this.filteredUsers.length / 25) }}
              </p>
            </div>

            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
										this.currPage === this.totalPage ||
											this.currPage == Math.ceil(this.filteredUsers.length / 25)
									"
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>
          <!-- end bottom pagination -->
        </div>
        <!-- end other column -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER")
    //Authorization: "Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6"
  }
};

export default {
  name: "UserDirectory",
  components: {
    AtomSpinner,
    Avatar
  },
  data() {
    return {
      server: process.env.VUE_APP_SERVER_URL,
      search: "",
      selectedCompany: "",
      acs: [],
      users: [],
      selectedCat: 0,
      loading: true,
      countOfPage: 25,
      currPage: 1,
      noUserFound: false
    };
  },
  methods: {
    firstLoad: function() {
      this.loading = true;
      this.$store.commit("setActivePage", "UserDirectory");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios.get(`${this.server}/api/v1/user-directory`, auth).then(
        response => {
          //console.log(response);
          this.users = response.data.users;
          this.loading = false;
        },
        error => {
          console.log(error);
          this.$router.push("/");
        }
      );

      console.log(colorGetter());
    },

    searchReset: function() {
      this.selectedCat = 0;
    },

    setPage: function(idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitcher: function(catID) {
      let category = catID;
      if (category !== 0) {
        this.selectedCat = category;
        this.search = "";
        console.log(category);
      } else {
        this.selectedCat = 0;
      }
    },

    setCompany(c) {
      this.search = "";
      let company = c;
      this.selectedCompany = company;
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      //return value.charAt(0).toUpperCase() + value.slice(1)
      return value.charAt(0) + value.slice(1).toLowerCase();
    }
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredACs: function() {
      let b = this.acs.filter(function(e) {
        return e.ACID <= 200;
      });

      return b;
    },

    //the main v-for loop for the users
    filteredUsers: function() {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.users.filter(e => {
          return (
            e.FirstName.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            e.LastName.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            e.Name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );

          //filter the subject line by what is filled in the search box
          //return e.LastName.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        });
      } else if (this.selectedCompany !== "") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.users.filter(f => {
          return f.Name == this.selectedCompany;
        });
      } else {
        //if the search box is not filled in
        return this.users;
      }
    },

    foundNoUser: function() {
      if (this.search.length > 1 && this.filteredUsers.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function() {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function() {
      return Math.ceil(this.users.length / this.countOfPage);
    }
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },
  mounted() {
    this.firstLoad();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.user {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.top-wrap {
  display: flex;
  align-items: flex-start;
}

.user-bottom {
  align-items: flex-end;
}

.user-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}

.capitalize {
  text-transform: capitalize;
}

.l1 {
  line-height: 1;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mlauto {
  margin-left: auto;
}

.user-link {
  color: #429da8;
  cursor: pointer;
}
.user-link a {
  color: #429da8;
  cursor: pointer;
}
.company-button {
  background: #efefef;
  color: #575757;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.company-button:hover {
  color: #fff;
  background: #00b4b4;
}

.company-button:active {
  color: #fff;
  background: #00b4b4;
}

.info-box {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 2px;
}
</style>
